<template>
  <div class="flex">
    <div class="top">
      <img class="img1" src="@/common/image/banner17.png" alt="" />
      <h3>循循尚购大众版</h3>
      <p>实现线上流量与线下流量的交互与共享,独家好物尽在循循尚购</p>
      <img src="@/common/image/图层 18.png" alt="">
      <h3>循循创收贝</h3>
      <p>从工具到流量，一站式解决线上线下商家的基本问题</p>
      <img src="@/common/image/hjasj19.png" alt="">
      <img class="image" src="@/common/image/图层 20.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: "download",
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("BOTTOM", true);
  },
  beforeDestroy() {
    this.$store.commit("BOTTOM", true);
  },
  watch: {},
  methods: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.top {
  min-width: 1200px;
  img {
    width: 100%;
  }
  h3 {
    font-size: 30px;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-top: 78px;
    margin-bottom: 18px;
  }
  p {
    font-size: 16px;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-bottom: 48px;
  }
  .image{
    display: block;
    margin-top: 76px;
  }
}
</style>
